import { PROMOTIONAL_PLANNING_PROMO_PANDL } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "numeral";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "P&L Lines", "search-by": "Code,Description", "ph-search-by": "Search by Code, Description" } }, [_c("url-field", { key: "Code", attrs: { "base-url": "/promotion-planning/pl-lines", "data-index": "code", "title": "Discount Code", "sorter": true, "width": 150 } }), _c("text-field", { key: "Description", attrs: { "data-index": "description", "title": "Discount Description", "sorter": true } }), _c("text-field", { key: "LineDescription", attrs: { "data-index": "lineDescription", "title": "Line", "sorter": true } }), _c("check-box-field", { key: "Extract", attrs: { "data-index": "extract", "title": "Extract", "align": "center", "width": 110 } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListPlLine",
  data() {
    return {};
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListPlLine = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-pl-line" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "promotion-planning.pllines", "api-url": _vm.apiUrl, "create-route": "/promotion-planning/pl-lines/create", "edit-route": "/promotion-planning/pl-lines/:id", "page": _vm.page } }, [_c("list-pl-line")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListPlLine
  },
  data() {
    return {
      apiUrl,
      page: PROMOTIONAL_PLANNING_PROMO_PANDL,
      itemsMenu: [
        {
          title: "Master Files",
          path: ""
        },
        {
          title: "P&L Lines",
          path: "/promotion-planning/pl-lines"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
